@tailwind base;
@tailwind components;
@tailwind utilities;


/* fonts.css */
body {
  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-style: normal;
  
}


/* Ensure ul and li elements are styled correctly */
ul {
  list-style-type: disc; /* Circle points */
  padding-left: 1.5em; /* Indent the list */
}

li {
  margin-bottom: 0.5em; /* Spacing between list items */
}

li p {
  margin: 0; /* Remove default margin from p tags inside li */
}




  /* /support css*/
  #support{
    background-image: url('../src//img/Rectangle\ 59.png');
     background-size:  cover;
    background-position:  center;
    
   
      
  }

  /* support box */

  @keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideFromTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-slide-left {
    animation: slideFromLeft 1s ease-in-out forwards;
  }
  
  .animate-slide-right {
    animation: slideFromRight 1s ease-in-out forwards;
  }
  
  .animate-slide-top {
    animation: slideFromTop 1s ease-in-out forwards;
  }
  
  .animate-slide-bottom {
    animation: slideFromBottom 1s ease-in-out forwards;
  }
  
  .animate-visible .box {
    opacity: 0;
  }
  
  .animate-visible .box.animated {
    opacity: 1;
  }
  








/* home about */

  .about-page-home {
    
    background-image: url('../src/img/9e96ba606ef03b66faaa34c9a567feb8.png');
     background-size:  cover;
    background-position:  center;
      
    }








/* map */




.world-map {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 1em;
  position: relative;
}

.world-map img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.title {
  text-align: center;
  color: white;
  margin: 2em 0;
  padding: 0 1em;
}

.pin {
  background: #4362f8;
  position: absolute;
  width: 1.5%;
  height: 1.5%;
  border-radius: 50%;
}

.pin::before {
  content: '';
  background: #4362f8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: pulse 1.3s ease-in-out infinite;
}

.pin span {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  left: 1.5em;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 3em;
  padding: 0.3em 0.6em;
  font-size: 0.9em;
}

.china {
  background: #4129e0;
  top: 57%;
  left: 73%; 
}

.india {
  background: #4129e0;
  top: 60%;
  left: 68.4%; 
}

.na {
  background: #4129e0;
  top: 54%;
  left: 48%; 
}

.wa {
  background: #4129e0;
  top: 63%;
  left: 44%; 
}

.sa {
  background: #4129e0;
  top: 78%;
  left: 54%; 
}

.ea {
  background: #4129e0;
  top: 67%;
  left: 58%; 
}

@keyframes pulse {
  100% {
    opacity: 0;
    transform: translate(-50%, -50%);
    scale: 2.5;
  }
}

@media screen and (max-width: 600px) {
  .pin {
    width: 2%;
    height: 2%;
  }

  .pin span {
    font-size: 0.7em;
    padding: 0.2em 0.4em;
  }
}

@media screen and (max-width: 400px) {
  .pin span {
    font-size: 0.5em;
    padding: 0.1em 0.3em;
  }
}

















/* video icons */



@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.animate-jump {
  animation: jump 0.5s ease-in-out infinite;
  animation-delay: 3s;
}

