.about-page-container {
  
  background-image: url('../img/image\ 1.png');
   background-size:  cover;
  background-position:  center;
    
  }
  .contact-page-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url('../img/krakenimages-Y5bvRlcCx8k-unsplash.jpg');
     background-size:  cover;
    background-position:  center;
    
  }
  
  
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #root {
    min-height: 100vh;
  }
  
 
